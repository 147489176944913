<template>
  <div class="layout_bottom">
    <div class="bottom_one">
      <div class="">
        <div class="inner">
          <div class="left_center">
            <div class="center_1">
              <img src="@/assets/images/loanImg/logo_icon_2.png" alt="">
              <h2 class="title">EasyLend</h2>
            </div>
          </div>
          <div class="right_center">
            <div>
              <p>Email:</p>
              <p>help@easy-lend-app.com</p>
            </div>
            <div>
              <p>Service Time:</p>
              <p>+234 09051324873</p>
              <p>Monday to Sunday 9:00-18:30</p>
            </div>
            <div>
              <p>Address:</p>
              <p>2 SWEET JESUS AVENUE , AKUTE ROAD, <br>IFO, OGUN STATE</p>
            </div>
          </div>
          <div class="item_download" @click="downloadApp()">
            <img src="@/assets/images/loanImg/Google-play.png" alt="">
          </div>
        </div>
        <div class="inner2">
          <a href="/privacy">Privacy Policy</a>
          <a href="/terms">Terms & Conditions</a>
          <p>PROSPERITY LENDING LIMITED</p>
        </div>
      </div>
    </div>
    <div class="bottom_two">
      Copyright © EasyLend All rights reserved
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=easylend.credit.loan')
    }
  }
}
</script>
<style lang="scss" scoped>
.layout_bottom{
  min-width: 1200px;
  .bottom_one{
    background: #060300;
    color: #fff;
    padding: 90px 0 70px 0;
    .inner{
      width: 100%;
      padding:0px calc((100% - 1150px) / 2) 0;
      margin: 0 auto;
      overflow: hidden;
      display: flex;
    }
    .inner2{
      width: 100%;
      padding:0px calc((100% - 1150px) / 2) 0;
      margin: 0 auto;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      a,p{
        color: white;
        margin: 10px 0;
      }
    }
    .left_center{
      float: left;
      width: 20%;
      display: flex;
      align-items: center;
      // border-right: 1px solid #8D8D8D;
      .center_1{
        display: flex;
        img{
          width: 50px;
          height: 50px;
        }
        .title{
          display: flex;
          align-items: center;
          margin-left: 20px;
          width: 250px;
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
    .right_center{
      display: flex;
      overflow: hidden;
      width: 75%;
      justify-content: space-between;
      div{
        display: flex;
        flex-direction: column;
        margin-right: 10px;
      }
      p{
        font-size: 12px;
        line-height: 25px;
      }
      h4{
        margin-bottom: 15px;
        font-weight: 100;
      }
    }
    .item_download{
      display: flex;
      width: 200px;
      // background: black;
      color: white;
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      margin-left: 50px;
      img{
        width: 200px;
        height: 60px;
        margin-right: 5px;
      }
    }
  }
  .bottom_two{
    background: #4D4D4D;
    text-align: center;
    color: #FFFFFF;
    font-size: 20px;
    line-height: 80px;
  }
}
@media only screen and (max-width: 520px){
  .layout_bottom{
    min-width: calc(100vw) !important;
    width: calc(100vw) !important;
  }
  .bottom_one{
    padding: 20px  !important;
  }
  .bottom_two{
    font-size: 14px !important;
    line-height: 40px !important;
  }
  .inner{
    flex-direction: column;
    .right_center{
      flex-direction: column;
      margin: 15px 0;
    }
    .item_download{
      margin-left: 0 !important;
    }
  }
}
</style>
