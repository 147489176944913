<template>
  <div class="nav-inner">
    <div class="nav-tab">
      <div class="tab">
        <div class="hotel-logo">
          <span class="logo-img" />
          <span>EasyLend</span>
        </div>
        <ul class="hotel-li">
          <li
            v-for="(item,index) in navData.list"
            :key="index"
            :class="navData.index === index ? 'select-item under-line': 'select-item'"
          >
            <a :href="item.link">{{ item.name }}</a>
          </li>
        </ul>
      </div>
    </div>
    <!-- <img class="nav_bg" src="~@/assets/images/loanImg/nav_bg.png"> -->
  </div>
</template>

<script>
export default {
  props: {
    navData: {
      type: Object,
      default: function() {
        return null
      }
    }
  },
  methods: {
    goAnchor(selector) {
      this.$router.push({ name: selector })
    }
  }
}
</script>

<style lang="scss" scope>
.nav-inner {
  width: calc(100vw);
  background: #fff;
  padding:0px calc((100% - 1200px) / 2) 0;
  position: relative;
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 99999;
  .nav-tab {
    height: 60px;
    align-items: center;
    margin: 0 auto !important;
    position: relative;
    z-index: 99999;
    // padding: 10px 0;
    .tab{
      margin: 0 auto;
    }
    .hotel-logo {
      float: left;
      height: 60px;
      line-height: 60px;
      span:nth-child(1) {
        display: inline-block;
        width: 44px;
        height: 60px;
        vertical-align:middle;
        background: url("~@/assets/images/loanImg/logo_2.png") no-repeat center;
        background-size: contain;
      }
      span:nth-child(2){
        display: inline-block;
        font-size: 18px;
        font-weight: 700;
        vertical-align:middle;
        margin-left: 10px;
      }
      // .logo-img{
      //   background: url("~@/assets/images/loanImg/logo_2.png") no-repeat center;
      // }
    }
    .hotel-li {
      float: right;
      height: 100%;
      li {
        display: inline-block;
        position: relative;
        // width: 16.66%;
        padding: 0 20px;
        height: 100%;
        font-size: 16px;
        text-align: center;
        line-height: 60px;
        white-space:nowrap;
        cursor: pointer;
        a{
          color: #999999;
          text-decoration: none;
        }
        &.under-line{
          color: $color-EE7E01;
          a{
            color: #EE7E01;
          }
          &::after{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0px;
            content: '';
            width: 90px;
            height: 3px;
            background: $color-EE7E01;
          }
        }
      }
    }
  }
  .nav_bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 10%
  }
}
@media only screen and (max-width: 665px) {
  .nav-tab {
    height:70px!important;
    width: calc(100vw);
    z-index: 99999;
    .hotel-logo{
      .logo-img{
        margin-left: 20px;
        width: 40px !important;
        height: 60px !important;
      }
    }
    .hotel-li{
      background: white;
      width: calc(100vw);
      display: flex;
      li{
        line-height: 50px !important;
        padding: 0 10px !important;
      }
      .select-item {
        flex: 1;
      }
      a{
        font-size: 12px !important;
      }
    }
  }
  .nav_bg{
    width: 50px !important;
  }
}
</style>
