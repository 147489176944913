<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <h1>Welcome to <span>EasyLend</span> </h1>
        <!-- <h1>Your trusted financial</h1> -->
        <!-- <h1>partner in Nigeria</h1> -->
        <h4>Your reliable and convenient financial solution in Nigeria. </h4>
        <h4>EasyLend is here to simplify your financial journey.</h4>
        <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/loanImg/Google-play.png" alt="">
        </div>
      </div>
    </div>
    <div id="abouts" class="inner inner2">
      <div class="content">
        <div class="right_cell">
          <img src="~@/assets/images/loanImg/about_us.png">
        </div>
        <div class="left_cell">
          <h2>About Us</h2>
          <p class="inner1_text">EasyLend is a fast and convenient loan app that allows you to access quick cash
            whenever and wherever you need it. Our mission is to provide financial assistance to
            individuals who are looking for a simple and reliable solution to meet their immediat</p>
        </div>
      </div>
    </div>
    <div id="loan" class="inner inner3">
      <h1>Loan</h1>
      <p :style="{color:'#999999'}">EasyLend offers a range of features and benefits designed to meet your loan needs</p>
      <div class="flex jc-sb content">
        <div class="flex fd-c jc-sb">
          <p>1.Instant Loans: Need cash urgently? With EasyLend,
            you can apply for a loan and receive funds directly to your
            mobile wallet instantly. No paperwork, no hassle.</p>
          <p>3. Competitive Interest Rates: At EasyLend, we believe
            in fair and transparent lending. Our interest rates are
            competitive and affordable, ensuring that you can borrow
            money without breaking the bank.</p>
          <p :style="{color:'white'}">
            5.24/7 Customer Support: Have a question or need
            assistance? Our dedicated customer support team
            is available 24/7 to assist you. Contact us via email
            and we’ll be happy to help.
          </p>
        </div>
        <img src="~@/assets/images/loanImg/about_us2.png">
        <div class="flex fd-c jc-sb">
          <p>2. Flexible Repayments: We understand that financial
            situations can change. That’s why we offer flexible
            repayment options tailored to your convenience.
            Repay your loan in installments or all at once,
            the choice is yours.</p>
          <p>
            4.Secure Transactions: We prioritize the security and
            privacy of your financial information. Rest assured
            that all transactions made through the EasyLend
            app are encrypted and safeguarded.
          </p>
          <p :style="{color:'white'}">
            6.Fast and Convenient: With EasyLend, you can
            apply for a cash loan anytime, anywhere, right from
            your phone. The application process is easy and can
            be completed within minutes.
          </p>
        </div>
      </div>
    </div>
    <div id="work" class="inner inner4">
      <h1>How It Work</h1>
      <h2>Applying for a loan with EasyLend is as easy as 1-2-3. Here’s how it works:</h2>
      <div class="flex al-c jc-c">
        <div class="carousel-container">
          <div class="carousel">
            <transition-group name="fade" tag="div" class="carousel-wrapper">
              <div v-for="(item) in inner_4items" :key="item.id" class="carousel-item">
                <p class="title">{{ item.id }}</p>
                <img :src="item.img">
                <!-- <h1>{{ item.txt }}</h1> -->
                <p>{{ item.txt2 }}</p>
                <img v-if="Number(item.id) !== inner_4items.length" class="right" src="@/assets/images/xiangyou@2x.png">
              </div>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inner_4items: [
        // 您的轮播内容数据
        { id: '1', name: 'Lando Wolf', txt: 'Descarga la App', txt2: 'Download and Install the App: Visit our website \n or your device’s app store to download and install \n EasyLend on your mobile phone. ', img: require('@/assets/images/icon-1.png') },
        { id: '2', name: 'Alice Ura', txt: 'Registrarse y verificar', txt2: 'Sign Up: Open the app and sign up for an account \n using your mobile number. We will send you a \n verification code to ensure the security of your \n account.', img: require('@/assets/images/icon-2.png') },
        { id: '3', name: 'Marino Zavaleta', txt: 'Completar la solicitud', txt2: 'Apply for a Loan: Once you have successfully \n signed up, you can apply for a loan by selecting \n the loan amount and the repayment duration that \n suits your needs. Our loan application process is \n quick and straightforward, with minimal \n requirements.', img: require('@/assets/images/icon-3.png') }
      ]
    }
  },
  methods: {
    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=easylend.credit.loan')
    }
  }
}
</script>

<style lang="scss" scoped>
  .home_top{
    min-width: 1200px;
    background: #fff;
    .inner{
      width: 1250px;
      // padding:50px calc((100% - 1100px) / 2) 50px;
      margin: 0 auto;
    }
    .bottom-20{
      margin-bottom: 20px;
    }
    .inner1{
      // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
      background: url("~@/assets/images/loanImg/inner1_bg.png") no-repeat;
      background-size: auto 100%;
      background-position-x: center;
      height: 38rem;
      display: flex;
      align-items: center;
      color: black;
      padding: 0 190px;
      .left_item{
        float: left;
        width: 740px;
        h1{
          font-size: 55px;
          // color: white;
          font-weight: 700;
          margin-bottom: 20px;
          line-height: 70px;
        }
        h4{
          padding-right: 17.5px;
          font-size: 16px;
          line-height: 26px;
          font-weight: normal;
          // margin-bottom: 10px;
        }
        // span{
        //   color: #FF5833;
        // }
        .item_progress{
          width: 230px;
          p{
            width: 90px;
            text-align: center;
            line-height: 30px;
            border-radius: 5px;
            color: white;
            font-size: 12px;
            font-weight: bold;
            margin: 0 auto 10px;
            background: #4488EB;
          }
          img{
            width: 245px;
            height: 15px;
          }
        }
        .item_info{
          margin: 20px 0;
          display: flex;
          height: 100px;
          .info_img1{
            width: 15px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            p{
              flex: 1;
              display: flex;
              box-sizing: border-box;
              // margin-left: 10px;
              font-size: 12px;
              color: #666666;
              align-items: center;
              img{
                margin-right: 10px;
              }
            }
          }
          .info_img2{
            width: 135px;
            height: 135px;
          }
        }
        .item_download{
          display: flex;
          margin-top: 40px;
          width: 200px;
          // background: black;
          color: white;
          border-radius: 5px;
          align-items: center;
          justify-content: center;
          img{
            width: 200px;
            height: 60px;
            margin-right: 5px;
          }
        }
      }
      .right_item{
        float: right;
        // margin-right: 50px;
        img{
          width: 550px;
        }
      }
    }
    .inner2{
      padding: 0px 0;
      // background: url("~@/assets/images/loanImg/inner1_2.png") no-repeat;
      // background: linear-gradient(#F19A47, #EF684B);
      // background-size: 100% 100%;
      background: linear-gradient(to bottom,#FE9A11 ,#F56732 );
      .content{
        display: flex;
        padding: 30px 190px 0;
        color: white;
        .left_cell,.right_cell{
          flex: 1;
        }
        .left_cell{
          padding: 80px 50px 20px 50px;
          // background: linear-gradient(to bottom,#ED7E00 0% ,#ED7E00 5%, #A4C519 5% ,#A4C519 100%);
          h2{
            font-size: 40px;
            line-height: 55px;
            text-align: center;
          }
          p{
            line-height: 25px;
            margin-top: 20px;
          }
        }
        .right_cell{
          img{
            height: 340px;
            display: block;
          }
        }
      }
    }
    .inner3{
      background: linear-gradient(to bottom,#fff 0%,#fff 78%,#FE9A11 78% ,#F56732 100%);
      text-align: center;
      padding: 100px 190px 0;
      h1{
        font-size: 40px;
        line-height: 80px;
      }
      img{
        width: 30%;
      }
      .content{
        margin-top: 100px;
        div{
          width: 32%;
          text-align: left;
          padding: 0 0 40px 0;
          font-size: 12px;
          line-height: 22px;
        }
        p{
          margin-bottom: 20px;
        }
      }
    }
    .inner4{
      padding: 70px 150px 40px;
      h1{
        font-size: 40px;
        color: black;
        text-align: center;
        position: relative;
        line-height: 80px;
      }
      h2{
        text-align: center;
        font-size: 12px;
        color: #999999;
        margin-bottom: 30px;
      }
      p{
        padding: 20px 60px;
        font-size: 12px;
        line-height: 23px;
        color: #666666;
      }
      .carousel-container {
        position: relative;
        width: 100%;
        height: 400px;
      }
      .carousel {
        display: flex;
        overflow: hidden;
        height: 100%;
      }
      .carousel-wrapper {
        display: flex;
        width: 100%;
        justify-content:space-around;
        align-items: center;
      }
      .carousel-item {
        width: 33.3%;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        background: url("~@/assets/images/content_bg.png") no-repeat center;
        background-size: 120% 100%;
        color: #fff;
        border-radius: 5px;
        font-size: 18px;
        padding: 30px 0 0;
        position: relative;
        // margin:  0 10px;
        .title{
          color: #fff;
          font-size: 20px;
          font-weight: bold;
        }
        h1{
          color: black;
          font-size: 15px;
          line-height: 20px;
          margin-top: 25px;
          text-align: left;
          height: 35px;
          padding: 0 20px;
        }
        p{
          font-size: 12px;
        }
        img{
          width: 40px;
        }
        .right{
          position: absolute;
          top: calc(50% - 30px);
          right: -35px;
          width: 65px;
          z-index: 9999;
        }
      }
      .fade-enter-active,
      .fade-leave-active {
        transition: opacity 0.2s, transform 0.2s;
      }

      .fade-enter,
      .fade-leave-to {
        opacity: 0;
        transform: translateX(300px);
      }
    }
  }
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
      width: 100vw;
    }
    .inner1{
      height: 270px;
      position: relative;
      padding: 0 20px;
      h1{
        font-size: 18px !important;
        line-height: 25px !important;
        margin-bottom: 0 !important;
      }
      .left_item{
        margin: 25px 0;
        .item_download{
          margin-top: 20px;
        }
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 350px !important;
          position: absolute;
          bottom: 0;
        }
      }
    }
    .inner2{
      padding: 30px 0;
      background: white !important;
      color: #333;
      .content{
        padding: 0 20px !important;
        display: flex;
        flex-direction: column;
        .left_cell{
          padding: 20px 0;
          h2{
            padding: 20px;
            font-size: 25px;
            line-height: 35px;
          }
          p{
            color: #333;
          }
        }
        .cell_item{
          width: 100% !important;
          height: 100px;
        }
        .inner1_text{
          margin: 0;
          padding: 0 20px;
        }
        img{
          margin-top: 0px;
          width: 300px !important;
          height: 300px !important;
          margin: 0 auto ;
        }
      }
    }
    .inner3{
      padding: 0 15px;
      width: 100%;
      margin-top: 0px;
      h1{
        width: 100%;
        font-size: 25px;
      }
      .content{
        margin-top: 60px;
        img{
          display: none;
        }
        div{
          width: 49%;
          p{
            margin: 10px 0;
            font-size: 14px;
            line-height: 16px;
          }
          padding: 0;
        }
      }
    }
    .inner4{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw) !important;
      margin: 0 auto !important;
      padding: 20px 15px;
      div{
        width: 100%;
      }
      .right{
        display: none;
      }
      p{
        padding: 10px 0 20px;
      }
      h1{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 20px;
      }
      .left_bg{
        width: 30px;
        height: 50px;
        top: 40px;
      }
      .inner5_item{
        margin:  0 auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        .content{
          flex: 1;
          width: 100%;
          margin: 20px 0;
        }
      }
      .carousel-container{
        height: 1500px;
      }
      .carousel-wrapper{
        padding: 0 20px;
        flex-wrap: wrap;
        .carousel-item{
          width: 45%;
          margin: 0 5px;
        }
      }
      .carousel-item{
        width: 100% !important;
        height: 450px;
        img{
          width: 60px;
        }
        h1{
          font-size: 13px;
          height: 40px;
          padding: 0;
          line-height: 16px;
          text-align: center;
        }
        p{
          font-size: 12px;
          height: 90px;
          padding: 0 70px;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
